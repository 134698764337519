<template>
  <Layout>
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">{{greet}}, {{getUsername()}}!</h4>
                  <p class="text-muted mb-0">
                    Here's what's up with your training today
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p id="page-description">
          <span
            >This page retrieves a <strong>protected message</strong> from an
            external API.</span>
          <span
            ><strong
              >Only authenticated users can access this page.</strong
            ></span>
        </p>
        <CodeSnippet title="Protected Message" :code="message" />
        <span>{{message}}</span>
        <div class="row">
          <div class="col-xl-8">
              <History />
            </div>
            <div class="col-xl-4">
              <Schedule />
            </div>
        </div>

  </Layout>
</template>



<script>
  import Layout from "../../layouts/main.vue";
  import { getProtectedResource } from "@/services/message.service";
  import { getSyncUser } from "@/services/user.service";
  import { ref } from "vue";
  import History from "./history";
  import Schedule from "./schedule";
  import { useAuth0 } from "@auth0/auth0-vue";

  export default {
    setup() {
        const message = ref("");
        var greet = "";
        const { getAccessTokenSilently } = useAuth0();
        const { user } = useAuth0();
        const getMessage = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getProtectedResource(accessToken);
          
            if (data) {
              message.value = JSON.stringify(data, null, 2);
            }
          
            if (error) {
              message.value = JSON.stringify(error, null, 2);
            }
        };
        const syncUser = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getSyncUser(accessToken);
            console.log("Data: " + data);
            if (data) {
              message.value = JSON.stringify(data, null, 2);
            }
            
            if (error) {
              message.value = JSON.stringify(error, null, 2);
            }
        };
        const greeting = () => {
          const myDate = new Date();
          const hrs = myDate.getHours();

          

          if (hrs < 12){
            greet = 'Good Morning';
          }else if (hrs >= 12 && hrs <= 17){
            greet = 'Good Afternoon';
          }else if (hrs >= 17 && hrs <= 24){
            greet = 'Good Evening';
          }
          return greet;
        }
        greeting();
        getMessage();
        syncUser();
        return {
            message,
            greet,
            getUsername: function() {
              return user.value.given_name;
            }
        }
    },
    components: {
      Layout,
      History,
      Schedule,
    },
    data() {
      return {
       
      };
    },
    methods: {
      rightcolumn() {
        if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
          document.querySelector('.layout-rightside-col').classList.remove('d-none')
        } else {
          document.querySelector('.layout-rightside-col').classList.add('d-none')
        }
      }
    }
}
</script>

