import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const getSyncUser = async (accessToken) => {
    const config = {
      url: `${apiServerUrl}/api/user/sync`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    const { data, error } = await callExternalApi({ config });
    console.log(data);
    return {
      data: data || null,
      error,
    };
  };
